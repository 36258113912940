import React, { useState } from "react"
import css from "@styled-system/css"
import { Link } from "gatsby"
import { useAuth } from "contexts/auth"

import {
  Alert,
  Box,
  Button,
  Heading,
  Input,
  SEO,
  Spinner,
  Text,
  Wrapper,
} from "components"

import ErrorDisplay from "../_errorDisplay"

function Forgot() {
  // Retrieve the forgot password function from the auth context
  const { forgotPassword } = useAuth()

  // Managing form state
  const [formState, setFormState] = useState("")

  // Hold variables from inputs
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [failedAttempt, setFailedAttempt] = useState(false)

  const forgotSubmit = event => {
    // Block default HTML5 submit
    event.preventDefault()

    // Run form validation
    let error = false
    setEmailError("")
    if (!/\S+@\S+\.\S+/.test(email)) {
      error = true
      setEmailError(
        "Invalid email address. Please check your spelling and try again."
      )
    }

    if (email.length === 0) {
      error = true
      setEmailError("Please enter an email address in this field.")
    }

    if (!error) {
      // Submit to server
      setFormState("processing")
      forgotPassword(
        email,
        () => {
          setFormState("success")
        },
        () => {
          setFormState("")
          setFailedAttempt(true)
        }
      )
    }
  }

  return (
    <>
      <SEO
        title="Forgot Password | Teine"
        description="Forgot your password for the Shareholder's Portal?"
      />
      <Box
        width={["100%", "calc(100% - 64px)"]}
        mx="auto"
        minHeight="50vh"
        mt={[128, 224]}
        bg="wash"
      >
        <Wrapper
          py={["layout.7", "layout.8"]}
          maxWidth={600}
          mx="auto"
          css={css({
            transition: "opacity 200ms ease-out",
            "> *": {
              py: 16,
            },
          })}
          style={
            formState === "processing"
              ? {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                }
              : undefined
          }
        >
          <Heading
            children={"Forgot password"}
            mb={["layout.4", "layout.6"]}
            size={800}
            textAlign="center"
          />

          {/* Processing State */}
          {(formState == "" ||
            formState == "processing" ||
            formState == "failure") && (
            <Box>
              {failedAttempt && (
                <Box mb={"layout.2"}>
                  <Alert
                    heading="Error submitting reset email"
                    text="Your submission attempt has returned an error. Please try again. If you continue having submission issues, please contact us."
                  />
                </Box>
              )}
              <Text mb="layout.2">
                To reset your password, enter the email below that corresponds
                to your shareholder account. We will send you a link to reset
                your password.
              </Text>
              <Box as="form" onSubmit={forgotSubmit} mt="layout.4" noValidate>
                <Box
                  css={css({
                    "> *": {
                      py: 16,
                    },
                  })}
                >
                  <Box>
                    <Input // REQUIRED FIELD
                      id="forgot-email"
                      name="email"
                      type="email"
                      label="Email"
                      required="true"
                      placeholder="Your email"
                      value={email}
                      onChange={event => {
                        setEmail(event.target.value)
                      }}
                    />
                  </Box>
                </Box>
                {/* Email Error Text */}
                {emailError && <ErrorDisplay message={emailError} />}

                <Box mt={["layout.2", "layout.4"]}>
                  <Button
                    children="Send Reset Request"
                    type="submit"
                    opacity={formState == "processing" ? "0.5" : "1"}
                    css={css({
                      pointerEvents: formState ? "none" : "auto",
                    })}
                  />
                </Box>
              </Box>
              {/* Processing Message */}
              <Box
                position="absolute"
                top={0}
                left={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                opacity={0}
                css={{
                  transition: "opacity 200ms ease-out",
                  pointerEvents: "none",
                  userSelect: "none",
                }}
                style={
                  formState === "processing"
                    ? {
                        opacity: 1,
                      }
                    : undefined
                }
              >
                <Spinner />
              </Box>
            </Box>
          )}
          {/* Successful Submission */}
          {formState === "success" && (
            <>
              <Text children="Your form has been successfully submitted. If you are registered in our system, a message with instructions for changing your password has been sent to your email address. You can close this window." />
            </>
          )}
        </Wrapper>
      </Box>
    </>
  )
}

export default Forgot
