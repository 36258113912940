import React from "react"

import { Box, Text } from "components"

const ErrorDisplay = ({ message }) => (
  <Box pt="layout.1">
    <Text children={"↳ " + message} size={300} color="red" />
  </Box>
)

export default ErrorDisplay
